<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <slot />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import LayoutDefault from '@/layouts/Login.vue'
export default {
  data: () => ({
    drawer: null,
  }),
  props: {
    source: String,
  },
};
</script>
