<template>
  <layout-login>
    <v-card class="elevation-12">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Signup</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="login"
            prepend-icon="fa-sign-in-alt"
            name="login"
            label="E-mail"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="password"
            id="password"
            prepend-icon="fa-key"
            name="password"
            label="Password"
            type="password"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="doSignup">Register</v-btn>
      </v-card-actions>
    </v-card>
    <v-alert v-if="hasErrors" :value="true" color="error" icon="fa-error">
      {{ lastError }}
    </v-alert>
  </layout-login>
</template>
<script>
import LayoutLogin from "@/layouts/Login.vue";
import REGISTER_MUTATION from "@/graphql/mutation/RegisterMutation.gql";
import { onLogin } from "@/vue-apollo";

export default {
  data: () => {
    return {
      login: null,
      password: null,
      token: null,
      user: null,
      lastError: "Unknown error",
      hasErrors: false,
      loading: false,
    };
  },
  components: {
    LayoutLogin,
  },
  methods: {
    async doSignup() {
      const variables = {
        name: this.login,
        email: this.login,
        password: this.password,
      };

      this.$apollo
        .mutate({
          mutation: REGISTER_MUTATION,
          variables,
          update: async (store, { data, errors }) => {
            if (errors) {
              return;
            }
            const apolloClient = this.$apollo.provider.defaultClient;
            await onLogin(apolloClient, data.registerUser.token);
            this.$router.push({ path: "/" });
          },
        })
        .catch((error) => {
          this.hasErrors = true;
          this.loading = false;
          this.lastError = error;
        });
    },
  },
};
</script>
